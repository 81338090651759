<template lang="pug">
.history.pb-5
  AppTitle(label="Historial")
  AppFormInput(type="select" label="Filtrar por" :options="expenseTypes" v-model:value="currentExpenseType")
  AppExpense.mb-5(
    v-for="expense in expenses"
    :name="expense.name"
    :fecha="expense.fecha"
    :status ="expense.status_invoice_expense"
    :emisor="expense.nombre_emisor"
    :total="expense.total"
    :category="expense.category_id[1]"
    @click="toDetails(expense.id)"
  )
</template>

<script>
import AppTitle from "@/components/AppTitle";
import AppExpense from "@/components/AppExpense";
import { AppFormInput } from "gignet-components";
import { store } from "@/store";
import { axios } from "@/utils/axios";
import { ref } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { onError } from "@/utils/toastify";
export default {
  components: { AppTitle, AppFormInput, AppExpense },
  setup() {
    const currentExpenseType = ref("PUBLICADO");
    const route = useRouter();
    const expenses = ref([]);
    const expenseTypes = [
      {
        value: "PUBLICADO",
        label: "PUBLICADO",
      },
      {
        value: "AUTORIZADO",
        label: "AUTORIZADO",
      },
      {
        value: "RECHAZADO",
        label: "RECHAZADO",
      },
    ];

    const toDetails = (id) => {
      route.push({ name: "ExpenseDetails", params: { id } });
    };

    const getExpenses = async () => {
      try {
        store.loading = true;
        const { data } = await axios.get("expenses/invoices", {
          params: { type: currentExpenseType.value, limit: 0 },
        });
        expenses.value = data.payload;
      } catch (error) {
        onError("Intenta de nuevo");
      } finally {
        store.loading = false;
      }
    };

    watch(
      () => currentExpenseType.value,
      async () => {
        expenses.value = [];
        await getExpenses();
      },
      { immediate: true }
    );

    return {
      expenseTypes,
      currentExpenseType,
      getExpenses,
      expenses,
      toDetails,
    };
  },
};
</script>

<style lang="scss" scoped></style>
